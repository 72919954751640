<template>
  <el-container>
    <el-aside>
      <div class="title">{{pageTitle}}</div>
      <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" background-color="#1144ab" text-color="#fff"
        router active-text-color="#fff">
        <el-menu-item index="/company/dashboard">
          <i class="el-icon-s-platform"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="/company/apps">
          <i class="el-icon-menu"></i>
          <span slot="title">应用管理</span>
        </el-menu-item>
        <el-menu-item index="/company/manage">
          <i class="el-icon-setting"></i>
          <span slot="title">企业管理</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <div class="header">
        <div class="header-right">
          <el-dropdown @command="clickDropDown">
            <div class="user-drop">
              <img class="avatar" src="@/assets/images/home/avatar.png" alt="">
              <div class="name"> {{ username }}</div>
              <i class="el-icon-arrow-down el-icon--right" style='font-size:16px'></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="home">门户首页</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>

          </el-dropdown>
        </div>
        <breadcrumb></breadcrumb>
      </div>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script>
import breadcrumb from '@/components/breadcrumb'
export default {
  components: {
    breadcrumb,
  },

  created () {
    let userInfo = window.localStorage.getItem('ecolUserInfo');
    this.username = userInfo ? JSON.parse(userInfo).username : '';
  },
  computed: {
    activeIndex () {
      const basePaths = ['/company/apps', '/company/manage'];
      const active = basePaths.find(path => this.$route.path.includes(path));
      return active || this.$route.path;
    },
     pageTitle() {
      return this.$store.state.config.top || '南京智能计算中心生态应用平台';
    }
  },
  methods: {
    clickDropDown (command) {
      switch (command) {
        case 'logout':
          window.localStorage.removeItem('ecolUserInfo');
          window.localStorage.removeItem('company')
          this.$router.push({
            path: '/'
          });
          break;
        case 'home':
          this.$router.push({
            path: '/'
          });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-container {
  width: 100vw;
  height: 100vh;
}
.el-aside {
  box-sizing: border-box;
  padding-top: 20px;
  width: 230px !important;
  background: #1144ab;
  .title {
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: PangMenZhengDao;
  }
}
.el-menu {
  /* height: 100%; */
  width: 210px;
  border: none;
  margin: 0 auto;

  i {
    color: #fff;
  }
  .el-menu-item.is-active {
    background: #013293 !important;
  }
}
.header {
  position: relative;
  padding: 0px;
  margin-bottom: 20px;
  .header-right {
    position: absolute;
    right: 10px;
    .user-drop {
      display: flex;
      align-items: center;
      .name {
        margin-left: 10px;
        font-size: 14px;
      }
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>