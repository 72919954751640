<template>
  <div class="tags-navigation">
    <el-tag v-for="(item, index) in openedTags" :key="index" :ref="`tag-${index}`"
      :class="{ active: activeTag === index }" closable @close="handleClose(index)" @click="handleClick(index)">
      {{ item.title }}
    </el-tag>
  </div>
</template>

<script>


export default {
  data () {
    return {
      openedTags: [],
      activeTag: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const tag = { title: to.name, path: to.path };
        let existingTagIndex = this.openedTags.findIndex(
          (t) => t.path === tag.path || t.title === tag.title
        );

        if (existingTagIndex !== -1) {
          this.activeTag = existingTagIndex;
        } else {
          this.openedTags.push(tag);
          this.activeTag = this.openedTags.length - 1;
        }
        this.$nextTick(() => {
          this.$refs[`tag-${this.activeTag}`][0].$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        })
      },
    },
  },
  methods: {
    handleClose (index) {
      let newPath = null;
      if (index > 0) {
        newPath = this.openedTags[index - 1].path;
      }
      this.openedTags.splice(index, 1);
      if (newPath !== null) {
        this.$router.push(newPath);
      }
      if (this.activeTag === index) {
        this.activeTag = null;
      } else if (this.activeTag > index) {
        this.activeTag--;
      }
    },
    handleClick (index) {
      this.activeTag = index;
      this.$router.push(this.openedTags[index].path);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-navigation {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  .el-tag {
    /* padding: 0 50px; */
    font-size: 14px;
    background: #fff;
    border-radius: 2px;
    height: 32px;
    line-height: 32px;
    border: none;
    color: #253858;
    vertical-align: middle;
    margin-right: 20px;
    cursor: pointer;
    &.active {
      background: #1144ab;
      color: #fff;
    }
  }
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #e9e3e3;
  }
  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #1144ab;
  }
}
</style>
